import React from "react";
import Helmet from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/Layout";
import Image from "../components/Image";
import styles from "../styles/page.module.scss";

const About = () => {
  const data = useStaticQuery(graphql`
    query imageQuery {
      fileName: file(relativePath: { eq: "images/leap-into-void.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const srcInfo = (
    <>
      <a href="https://www.metmuseum.org/art/collection/search/266750">
        Leap into void
      </a>{" "}
      de Yves Klein
    </>
  );

  const productionList = [
    {
      authors: "GONÇALVES, Rafael",
      url: "https://doi.org/10.20396/ideias.v13i00.8668218",
      title:
        "Automatismo ontem e hoje: reflexões sobre os limites da inteligência artificial a partir de Simondon",
      complement: "<em>Ideias</em>, v. 13, e022008, 21 jun. 2022.",
    },
    {
      authors: "GONÇALVES, Rafael; FERREIRA, Pedro P",
      url: "https://reunioes.sbpcnet.org.br/74RA/inscritos/resumos/1392_16e072f0ac3379ec7ebf29ee4ddbced95.pdf",
      title:
        "Aprendizado de máquina como mediação técnica computacional: viés de gênero no processamento automático de textos relacionando pronomes e profissões pelo algoritmo BERT",
      complement:
        "<em>Anais [recurso eletrônico]: resumos da 74ª Reunião Anual da SBPC</em>, 24 jul. 2022.",
    },
    {
      authors: "GONÇALVES, Rafael; FERREIRA, Pedro P",
      url: "https://www.prp.unicamp.br/inscricao-congresso/resumos/2022P20272A33708O343.pdf",
      title:
        "Eficiência como viés algorítmico nas técnicas de aprendizado de máquina: caracterização baseada na produção tecnocientífica da Google",
      complement: "<em>XXX Congresso de Iniciação Científica da Unicamp</em>, 2022.",
    },
  ];

  return (
    <>
      <Helmet title="Sobre" />
      <Layout isReadingPage header="Sobre" className={styles.wrapper}>
        <div className={styles.content}>
          <section className={styles.about}>
            <Image
              srcInfo={srcInfo}
              className={styles.image}
              gatsbyImgProps={{ fluid: data.fileName.childImageSharp.fluid }}
            />
            <div className={styles.body}>
              <p>
                Criei esta página com a intenção de reunir meus estudos recentes
                em socioantropologia da tecnociência. É, assim, local de
                registro e experimentação de meu processo pessoal de pesquisa.
                Meus interesses também incluem: teoria crítica; filosofia,
                sociologia e antropologia contemporâneas e estudos sociais da
                ciência e da tecnologia. Autores que me interessam em específico
                são Gilles Deleuze, Félix Guattari e{" "}
                <a
                  href="https://gregsifch.wordpress.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Gilbert Simondon
                </a>
                .
              </p>
              <p>
                Atualmente, sou mestrando em sociologia pelo IFCH/Unicamp e
                participo do{" "}
                <a
                  href="https://www.laspa.slg.br/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Laboratório da Sociologia dos Processos de Associação (LaSPA)
                </a>
                . Pesquiso sobre a modulação de comportamento operado por
                técnicas de aprendizado de máquina a partir da análise de
                patentes da Google. Possuo graduação em engenharia elétrica pela
                FEEC/Unicamp, onde pude estudar a área de aprendizado de
                máquina, tendo me dedicado em especial às redes neurais
                artificiais e ao aprendizado profundo.
              </p>
            </div>
          </section>
          <section className={styles.publications}>
            <header>
              <h2>Produções</h2>
            </header>
            <ul>
              {productionList.map(({ authors, title, complement, url }, i) => (
                <li key={i}>
                  {authors}{". "}
                  {url ? (
                    <a href={url} target="_blank" rel="noreferrer noopener">
                      {title}
                    </a>
                  ) : (
                    title
                  )}{". "}
                  <span dangerouslySetInnerHTML={{ __html: complement }} />
                </li>
              ))}
            </ul>
          </section>
        </div>
      </Layout>
    </>
  );
};

export default About;
