import React from "react";
import classnames from "classnames";
import Img from "gatsby-image";
import style from "./Image.module.scss";

const Image = ({ srcInfo, className, gatsbyImgProps }) => (
  <div
    className={
      className
        ? classnames(style.imageContainer, className)
        : style.imageContainer
    }
  >
    <Img className={style.image} {...gatsbyImgProps} />
    <div className={style.info}>{srcInfo}</div>
  </div>
);

export default Image;
